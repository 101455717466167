import React from 'react';
import styled from 'styled-components';
import { mobileThresholdPixels, colors } from './v3/styledComponents';
import Dots from './v3/Dots';
import Question from './Question';
import Modal from '../home/v2/Modal';
import { FormInput, Button } from './v2/styledComponents';
import checkZipcodeError from '../../core/services/zipcode';
import {
  mostFrequentQuestions,
  serviceQuestions,
  paymentsQuestions,
  customerCareQuestions,
  otherQuestions,
} from './faqQuestions';
import locationIcon from '../../assets/faq/locationIcon.svg';
import mostFrequentIcon from '../../assets/faq/mostFrequentIcon.svg';
import serviceIcon from '../../assets/faq/serviceIcon.svg';
import paymentsIcon from '../../assets/faq/paymentsIcon.svg';
import customerCareIcon from '../../assets/faq/customerCareIcon.svg';
import otherIcon from '../../assets/faq/otherIcon.svg';
import errorLogo from '../../assets/error.png';
import verifiedLogo from '../../assets/verified.png';

const Container = styled.div``;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 66px;
  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 50px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
  margin-bottom: 40px;
`;


const MostFrequentContainer = styled.div`
  background-color: ${colors.lightGrey};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 65px 0px;
  @media (max-width: ${mobileThresholdPixels}) {
    padding: 34px 0px 63px;
  }
`;

const OtherQuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 68px 0px 32px;

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 50px 0px 37px;
  }
`;

const Title1 = styled.h1`
  font-family: Libre Baskerville;
  font-weight: 500;
  line-height: 30px;
  font-size: 32px;
  text-align: center;
  color: ${colors.navy};
  margin: 0px 0px 20px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 24px;
    margin: 0px 15px;
  }
`;

const QuestionsContainer = styled.div`
  width: 1166px;
  padding: ${({ noPadding }) => noPadding ? '0px' : '32px 0px'};
  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
    padding: ${({ noPadding }) => noPadding ? '0px 23px' : '0px 23px 14px'};
    box-sizing: border-box;
  }
`;

const Title2 = styled.h2`
  color: ${colors.navy};
  font-family: Libre Baskerville;
  font-size: 26px;
  line-height: 29px;
  margin: 0px;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 18px;
  }
`;

const Icon = styled.img`
  margin-right: 11px;
`;

const ModalContainer = styled.div`
  width: 463px;
  padding: 46px 69px 59px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: ${mobileThresholdPixels}) {
    padding: 27px 0px 42px;
    width: 303px;
  }
`;

const TitleModal = styled.h2`
  color: ${colors.navy};
  font-family: Libre Baskerville;
  font-size: 26px;
  line-height: normal;
  margin: 0px;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 18px;
  }
`;

const FormInputContainer = styled.div`
  display: flex;
  margin-top: ${({ smallMarginTop }) => smallMarginTop ? '15' : '44'}px;
  justify-content: space-between;
  width: 460px;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 18px;
    width: 300px;
  }
`;

const StyledFormInput = styled(FormInput)`
  width: calc(100% - 15px);
  margin: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 38px;
  font-size: 18px;
  &::placeholder {
    color: ${colors.lightGrey4};
  }
  @media (max-width: ${mobileThresholdPixels}) {
    margin-left: 0px;
    margin-right: 0px;
  }
`;

const StyledButton = styled(Button)`
  margin: 43px auto 0px;
  height: 54px;
  min-height: unset;
`;

const ResponseText = styled.p`
  font-family: Roboto;
  font-size: 14px;
  color: ${colors.lightGrey4};
  margin-bottom: 0px;
  text-align: center;
  @media (max-width: ${mobileThresholdPixels}) {
    max-width: 276px;
  }
`;


const ErrorText = styled(ResponseText)`
  color: ${colors.red};
`;

const LocationIcon = styled.img`
  width: 27px;
  min-width: 27px;
  margin-right: 16px;
  @media (max-width: ${mobileThresholdPixels}) {
    width: unset;
    min-width: unset;
  }
`;

const EmptyDiv = styled.div`
  width: 27px;
  min-width: 27px;
  margin-left: 16px;
  @media (max-width: ${mobileThresholdPixels}) {
    width: 13px;
    min-width: 13px;
  }
`;

const InputAndImgContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${({ error }) => error && 'margin-bottom: -10px;'}
`;

const I = styled.i`
  background-image: ${({ error }) => error ? `url(${errorLogo})` : `url(${verifiedLogo})`};
  background-size: contain;
  width: 10px;
  height: 10px;
  position: relative;
  left: 88%;
  top: -26px;
  @media (max-width: ${mobileThresholdPixels}) {
    left: 92%;
  }
`;

class FAQ extends React.Component {
  constructor() {
    super();
    this.state = {
      zipcode: '',
      isModalOpened: false,
    };
    this.checkZipcode = this.checkZipcode.bind(this);
    this.openPopup = this.openPopup.bind(this);
  }

  openPopup() {
    this.setState({ isModalOpened: true });
  }

  checkZipcode() {
    const { zipcode } = this.state;
    const errorCode = checkZipcodeError(zipcode).code;
    this.setState({
      isOk: errorCode === 0,
      formatError: errorCode === 1,
      notDeliveredError: errorCode === 2,
    });
  }

  render() {
    const { isModalOpened, zipcode, isOk, formatError, notDeliveredError } = this.state;
    return (
      <Container>
        <Modal mobileFull={false} show={isModalOpened} closeRequest={() => this.setState({ isModalOpened: false })}>
          <ModalContainer>
            <TitleModal>Quel est votre code postal ?</TitleModal>
            {isOk &&
              <ResponseText>Nos Tillistes peuvent venir jusqu’à chez vous ;)</ResponseText>}
            {formatError && <ErrorText>Code postal non-valide</ErrorText>}
            {notDeliveredError &&
              <ResponseText>Nos Tillistes ne peuvent pas encore venir chez vous :(</ResponseText>}
            <FormInputContainer smallMarginTop={isOk || formatError || notDeliveredError}>
              <LocationIcon src={locationIcon} alt="Quel est votre code postal" />
              <InputAndImgContainer error={formatError === true || isOk === true}>
                <StyledFormInput
                  name="zipcode"
                  type="numeric"
                  placeholder="Ex: 91344"
                  onChange={e => this.setState({ zipcode: e.target.value })}
                  value={zipcode}
                />
                {(formatError === true || isOk === true) && <I error={formatError} />}
              </InputAndImgContainer>
              <EmptyDiv />
            </FormInputContainer>
            <StyledButton onClick={this.checkZipcode} navy>Valider</StyledButton>
          </ModalContainer>
        </Modal>
        <TitleContainer>
          <Title1>Vous avez besoin d‘aide ?</Title1>
          <Dots noMargin />
        </TitleContainer>

        <MostFrequentContainer>
          <QuestionsContainer noPadding>
            <Row>
              <Icon src={mostFrequentIcon} />
              <Title2>Questions fréquentes</Title2>
            </Row>
            {mostFrequentQuestions.map(question => (
              <Question
                key={question.question}
                openPopup={this.openPopup}
                question={question}
                alwaysOpened
              />
            ))}
          </QuestionsContainer>
        </MostFrequentContainer>

        <OtherQuestionsContainer>
          <QuestionsContainer>
            <Row>
              <Icon src={serviceIcon} />
              <Title2>Le service</Title2>
            </Row>
            {serviceQuestions.map((question, index) => (
              <Question
                key={question.question}
                openPopup={this.openPopup}
                question={question}
                marginBottom={index === serviceQuestions.length - 1}
                noTransition={index === 0}
              />
            ))}
          </QuestionsContainer>

          <QuestionsContainer>
            <Row>
              <Icon src={paymentsIcon} />
              <Title2>Les paiements</Title2>
            </Row>
            {paymentsQuestions.map((question, index) => (
              <Question
                key={question.question}
                question={question}
                marginBottom={index === paymentsQuestions.length - 1}
                noTransition={index === 0}
              />
            ))}
          </QuestionsContainer>

          <QuestionsContainer>
            <Row>
              <Icon src={customerCareIcon} />
              <Title2>SAV</Title2>
            </Row>
            {customerCareQuestions.map((question, index) => (
              <Question
                key={question.question}
                question={question}
                marginBottom={index === customerCareQuestions.length - 1}
                noTransition={index === 0}
              />
            ))}
          </QuestionsContainer>

          <QuestionsContainer>
            <Row>
              <Icon src={otherIcon} />
              <Title2>Autre</Title2>
            </Row>
            {otherQuestions.map((question, index) => (
              <Question
                key={question.question}
                question={question}
                marginBottom={index === otherQuestions.length - 1}
                noTransition={index === 0}
              />
            ))}
          </QuestionsContainer>
        </OtherQuestionsContainer>
      </Container>
    );
  }
}

export default FAQ;

