/* eslint-disable max-len */

export const mostFrequentQuestions = [
  { question: 'Quels sont les délais ?',
    answers: ['Nos délais standards sont de 3 à 5 jours. Vous fixerez votre date de rendu avec votre couturier lors de votre premier rendez-vous. Pour toute demande urgente, vous pouvez nous contacter au 01.84.80.05.39.',
      'Bien sûr, les délais peuvent varier selon le volume des retouches que vous aurez à effectuer.'] },
  { question: 'Où vous déplacez-vous ?',
    answers: ['Nous nous déplaçons à Paris et sa proche banlieue, Lyon, Marseille, Aix en Provence, Bordeaux, Toulouse, Lille, Rennes, Nantes, Strasbourg, Nice et Cannes.',
      'POPUP',
      'N’hésitez pas à nous envoyer un mail à contact@tilli.fr si vous souhaitiez vous voir proposer le service dans votre ville 😊.'] },
  {
    question: 'Quels sont les tarifs ?',
    answers: ['Vous trouverez la liste de nos tarifs en vous rendant sur le lien suivant : <link>https://tilli.fr/nos-tarifs<link>. Seules les retouches les plus demandées y sont précisées, mais vous pourrez trouver tous les prix en cliquant sur “voir tous nos tarifs” sous le tableau.'],
  },
  {
    question: 'Est-il est possible de faire de la création ?',
    answers: ['Nous vous proposons de créer des accessoires à partir de chutes de tissus et de vêtements existants. En revanche, nous ne faisons pas de création de vêtements car nous ne voulons pas réintroduire de nouvelles pièces sur le marché.'],
  },
];

export const serviceQuestions = [
  { question: 'Tilli, qu’est-ce que c’est ?',
    answers: [
      'Tilli, c‘est le premier service de couturiers à domicile. Nous avons décidé de vous bichonner en adaptant aux besoins d‘aujourd‘hui, le métier artisanal du couturier.',
    ] },
  { question: 'Comment ça marche ? ',
    answers: [
      'À dates et heures de votre choix, votre couturier se déplace chez vous, et pendant une demi-heure vous écoute et vous conseille. Une fois le devis validé, votre couturier récupère toutes vos affaires en atelier afin d’effectuer le travail demandé. 3 à 5 jours plus tard, vous récupérez vos affaires 😉.',
    ],
  },
  { question: 'Comment prendre rendez-vous ? ',
    answers: [
      'Rien de plus simple ! Cliquez sur « prendre RDV », ou rendez-vous sur ce lien : <link>https://tilli.fr/reserver-votre-couturier-a-domicile/<link>. Vous pouvez préciser les matières, les pièces ainsi que les retouches à faire. On vous indiquera un devis, qui n’est pas figé. Vous pourrez donc rajouter des pièces à retoucher, à broder ou à transformer pendant votre rendez-vous avec votre couturier.',
    ],
  },
  { question: 'Et faire un devis, c’est obligatoire ? ',
    answers: [
      'Non ! Mais on vous le conseille 😉. Cela nous permet de vous envoyer le couturier le plus qualifié pour vos demandes. Chaque matière est spécifique, et nous avons des couturiers aux compétences multiples et très différentes.',
    ],
  },
  { question: 'Qui sont les Tillistes ?',
    answers: [
      'Nos Tillistes sont des spécialistes stylistes / modélistes qui sont sélectionnés avec soin. Nous prenons le temps nécessaire afin de ne travailler qu’avec les meilleurs spécialistes, et ce quelle que soit votre ville.',
    ],
  },
  { question: 'Qu’est-ce que je peux faire ?',
    answers: [
      'Plein de choses ! Que ce soit sur vêtement ou sur linge de décoration, nous vous proposons tout autant des retouches dites basiques que des transformations complexes de vos plus belles pièces. Nous vous invitons à parcourir nos rubriques pour trouver l’inspiration sur <link>https://tilli.fr/la-rubrique/<link> 😉.',
    ],
  },
  { question: 'Est-il est possible de faire de la création ?',
    answers: [
      'Nous vous proposons de créer des accessoires à partir de chutes de tissus et de vêtements existants. En revanche, nous ne faisons pas de création de vêtements car nous ne voulons pas réintroduire de nouvelles pièces sur le marché.',
    ],
  },
  { question: 'Quels sont les délais ?',
    answers: [
      'Nos délais standards sont de 3 à 5 jours. Vous fixerez votre date de rendu avec votre couturier lors de votre premier rendez-vous. Pour toute demande urgente, vous pouvez nous contacter au 01.84.80.05.39.',
      'Bien sûr, les délais peuvent varier selon le volume des retouches que vous aurez à effectuer.',
    ],
  },
  { question: 'Où vous déplacez vous ?',
    answers: [
      'Nous nous déplaçons à Paris et sa proche banlieue, Lyon, Marseille, Aix en Provence, Bordeaux, Toulouse, Lille, Rennes, Nantes, Strasbourg, Nice et Cannes.',
      'POPUP',
      'N’hésitez pas à nous envoyer un mail à contact@tilli.fr si vous souhaitez vous voir proposer le service dans votre ville 😊.',
    ],
  },
  { question: 'Dois-je fournir du matériel, par exemple un zip ?',
    answers: [
      'Nos experts fournissent les basiques de leur atelier de couture (boutons, élastiques, doublures, zips, renforts thermocollants, etc.) dans des coloris, tailles et matières classiques.',
      'Pour toutes les demandes avec une forme ou un coloris particulier, nous vous demandons de vous les procurer avant votre rendez-vous.',
    ],
  },
  { question: 'Ai-je des choses à préparer avant mon rendez-vous ?',
    answers: [
      "Si votre commande porte sur des retouches de vêtements, vous n'avez rien à préparer avant la venue de votre couturier, sauf les vêtements à retoucher 😉.",
      "Si votre commande porte sur des retouches de rideaux, il est très important de les avoir lavés, repassés, installés puis laissés suspendus pendant 48H, pour s'assurer qu'ils ne se détendent pas après la retouche.",
    ],
  },
  { question: "J'ai beaucoup d'affaires à retoucher, est-ce qu'il est possible de gérer ma commande au fur et à mesure ?",
    answers: [
      'Bien sûr, et on vous le conseille ! Votre couturier pourra vous aider à prioriser et il pourra repartir avec une partie des affaires à retoucher. Lorsqu’il vous ramènera vos affaires, il pourra repartir avec des nouvelles choses en vous créant une nouvelle commande. Tout pourra être fait, mais au fur et à mesure 😉.',
    ],
  },
];

export const paymentsQuestions = [
  { question: 'Quels sont les tarifs ?',
    answers: [
      'Vous trouverez la liste de nos tarifs en vous rendant sur le lien suivant : <link>https://tilli.fr/nos-tarifs<link>. Seules les retouches les plus demandées y sont précisées, mais vous pourrez trouver tous les prix en cliquant sur "voir tous nos tarifs" sous le tableau.',
    ],
  },
  { question: 'Existe-t-il un minimum de commande ?',
    answers: [
      'Oui, un rendez-vous engage un minimum de commande de 25€ afin de sécuriser le déplacement de votre couturier et dédommager son temps de conseil passé avec vous.',
    ],
  },
  { question: 'Que comprennent les tarifs ?',
    answers: [
      'Le déplacement de votre couturier, la livraison de vos articles, le travail effectué, et un rendez-vous de réajustement si besoin.',
    ],
  },
  { question: 'Pourquoi me demandez-vous ma carte avant le premier rendez-vous ?',
    answers: [
      'Avant votre rendez-vous, l’enregistrement de vos coordonnées bancaires est nécessaire afin de sécuriser votre rendez-vous, et d’engager le déplacement d’un couturier modéliste professionnel 😊.',
    ],
  },
  { question: 'Quand dois-je payer ?',
    answers: [
      'Vous serez prélevé après votre premier rendez-vous lorsque vous aurez accepté le devis réalisé avec votre couturier.',
    ],
  },
  { question: 'Quels sont les moyens de paiement acceptés ?',
    answers: [
      'Avant de pouvoir utiliser pleinement les services proposés par Tilli, vous devez avoir renseigné vos coordonnées bancaires (visa ou mastercard). Dès lors, vous n’avez aucune démarche particulière à effectuer. Le paiement sécurisé est assuré par notre partenaire <link>Mangopay<link>.',
    ],
  },
  { question: 'Comment obtenir mes factures ?',
    answers: [
      'Vous pouvez les retrouver dans votre espace client, dans la catégorie « mes commandes », puis « détails de ma commande ». Vous pourrez les télécharger directement.',
    ],
  },
];

export const customerCareQuestions = [
  { question: 'Je dois annuler ou reporter un rendez-vous. Comment faire ?',
    answers: [
      'Il suffit de nous contacter par mail à contact@tilli.fr.',
      "Vous pouvez décaler votre rendez-vous avec votre couturier sous réserve que l'annulation soit faite au minimum 8h avant l'heure initiale du rendez-vous.",
      'Entre 8h et 2h avant, la somme de 14€ vous sera facturée, et moins de 2h avant ou pendant le rendez-vous, la somme de 25€ vous sera facturée.',
    ],
  },
  { question: 'Je dois modifier mes coordonnées, comment faire ?',
    answers: [
      'Vous pouvez vous rendre directement sur votre espace client 😊.',
    ],
  },
  { question: 'Est-il possible de changer mon adresse entre deux rendez-vous ?',
    answers: [
      'Bien sûr ! Demandez-le à votre couturier lors du premier rendez-vous.',
      'Si un impératif se présente entre vos 2 rendez-vous, n’hésitez pas à nous contacter par mail à contact@tilli.fr.',
    ],
  },
  { question: 'Si je commande à nouveau des retouches, puis-je avoir le même couturier que la fois précédente ?',
    answers: [
      'Oui évidemment, nous vous assignerons par défaut le même couturier sous réserve qu’il soit disponible à ce moment-là.',
      "Si votre couturier n’est pas disponible à l'heure souhaitée, un autre couturier vous sera assigné.",
    ],
  },
  { question: 'J’adore votre service, comment parrainer mes amis ?',
    answers: [
      'Dans votre application, sur votre compte client, vous trouverez votre code parrain. Si un de vos filleul passe commande, vous gagnez tous les deux 5€ de réduction ! Et c’est cumulable 😉.',
    ],
  },
  { question: 'Et si j’ai un problème avec mes retouches ?',
    answers: [
      "Un rendez-vous de réajustement est prévu si vous n’êtes pas satisfait de vos retouches. Et si le problème n'est pas récupérable, ce qui serait très surprenant, nous travaillons avec AXA assurance afin d'assurer toutes vos pièces.",
    ],
  },
];

export const otherQuestions = [
  { question: 'Comment travailler avec vous ?',
    answers: [
      'Vous êtes un couturier de talent et vous voulez exercer votre passion ? Alors rendez-vous ici : <link>https://tilli.fr/devenir-couturier/<link>.',
    ],
  },
  { question: 'Existe-t-il une application ?',
    answers: [
      'Bien sûr ! 😉 Vous pouvez télécharger notre application « Tilli » sur l’Apple Store et sur Google Play.',
    ],
  },
  { question: 'Comment utiliser mon code promo ?',
    answers: [
      'Vous pouvez renseigner votre code promo au moment de valider votre rendez-vous en cliquant sur “j’ai un code promo”.',
    ],
  },
];
