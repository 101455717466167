import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';

import withAppContext from '../../withAppContext';
import { colors, mobileThresholdPixels } from './v3/styledComponents';
import downIcon from '../../assets/faq/downIcon.svg';
import upIcon from '../../assets/faq/upIcon.svg';
import downIconSmall from '../../assets/faq/downIconSmall.svg';
import upIconSmall from '../../assets/faq/upIconSmall.svg';

const Container = styled.div`
  position: relative;
  left: 29px;
  outline: none;
  cursor: ${({ alwaysOpened }) => alwaysOpened ? 'default' : 'pointer'};

  @media (max-width: ${mobileThresholdPixels}) {
    left: ${({ alwaysOpened }) => alwaysOpened ? '0' : '29'}px;
    padding-right: ${({ alwaysOpened }) => alwaysOpened ? '0' : '29'}px;
  }
`;

const QuestionTitle = styled.h4`
  color: ${colors.navy};
  font-family: Libre Baskerville;
  font-size: 22px;
  margin-top: ${({ isOpened, noTransition }) => isOpened && !noTransition ? '52' : '36'}px;
  ${({ noTransition }) => !noTransition && 'transition: margin-top 0.2s ease-in-out;'}
  font-weight: 500;
  margin-bottom: ${({ isOpened }) => isOpened ? '10' : '36'}px;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 18px;
  }
`;

const Answer = styled.p`
  color: ${colors.navy};
  font-family: Roboto;
  font-size: 18px;
  margin: 0px;
  ${({ marginBottom }) => marginBottom && 'margin-bottom: 36px;'}
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 30px;
  }
`;

const Icon = styled.img`
  position: absolute;
  left: -29px;
  top: 7px;
`;

const Link = styled.span`
  text-decoration: underline;
  font-weight: 500;
  cursor: pointer;
`;

const A = styled.a`
  text-decoration: underline;
  font-weight: 500;
  color: ${colors.navy};
`;

const StyledGatsbyLink = styled(GatsbyLink)`
  text-decoration: underline;
  font-weight: 500;
  color: ${colors.navy};
`;

const renderAnswerWithLink = answer => (
  answer.split('<link>').map((subtext, index) => {
    const url = subtext.replace('https://tilli.fr', '');
    if ((index % 2 === 1) && url === 'Mangopay') {
      return (
        <span key={subtext}>
          <A href="https://www.mangopay.com/" target="_blank" rel="noopener noreferrer">
            {subtext}
          </A>
        </span>
      );
    }
    return (
      <span key={subtext}>
        {(index % 2 === 1) ?
          <StyledGatsbyLink to={url}>
            {subtext}
          </StyledGatsbyLink> :
          subtext
        }
      </span>
    );
  })
);

class Question extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpened: props.alwaysOpened,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    const { alwaysOpened } = this.props;
    if (alwaysOpened) return;
    this.setState({ isOpened: !this.state.isOpened });
  }


  render() {
    const { question, alwaysOpened, openPopup, context: { isMobile }, marginBottom, noTransition } = this.props;
    const { isOpened } = this.state;
    return (
      <Container key={question.question} onClick={this.toggle} alwaysOpened={alwaysOpened}>
        {!alwaysOpened && !isOpened && <Icon src={isMobile ? downIconSmall : downIcon} />}
        {!alwaysOpened && isOpened && <Icon src={isMobile ? upIconSmall : upIcon} />}
        <QuestionTitle isOpened={isOpened} noTransition={noTransition}>{question.question}</QuestionTitle>
        {question.answers.length === 1 && isOpened &&
          <Answer marginBottom={marginBottom}>
            {question.answers[0].includes('<link>')
              ? renderAnswerWithLink(question.answers[0])
              : question.answers[0]
            }
          </Answer>}
        {question.answers.length > 1 && isOpened &&
          question.answers.map((answer, index) => answer === 'POPUP'
            ? (<Answer key={answer}>
              {'- Vous voulez savoir si Tilli se déplace chez vous ? '}
              <Link onClick={openPopup}>
                Testez votre code postal
              </Link>
            </Answer>)
            : (
              <Answer key={answer} marginBottom={marginBottom && index === question.answers.length - 1}>
                {answer.includes('<link>')
                  ? renderAnswerWithLink(`- ${answer}`)
                  : `- ${answer}`
                }
              </Answer>
            ))
        }
      </Container>
    );
  }
}


Question.propTypes = {
  question: PropTypes.shape({}).isRequired,
  alwaysOpened: PropTypes.bool,
  openPopup: PropTypes.func,
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
  marginBottom: PropTypes.bool,
  noTransition: PropTypes.bool,
};

Question.defaultProps = {
  alwaysOpened: false,
  openPopup() {},
  marginBottom: false,
  noTransition: false,
};

export default withAppContext(Question);
